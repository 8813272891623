<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-10 col-sm-12">
        <div class="scanner-box">
          <h2 v-if="scan" class="text-center mb-4">Сканер QR-кодів</h2>
          <div v-if="scan" class="video-container">
            <video ref="video" width="100%" height="auto" autoplay></video>
          </div>
          <h1 v-if="qrCode" class="qr-code-result" style="color: green;font-size: x-large">Сканування успішне!</h1>
          <div class="text-center justify-content-center">
            <img v-if="qrCode" src="@/assets/scan.png" alt="Scan Image" class="text-center" style="width: 30%">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BrowserQRCodeReader } from '@zxing/library';
import { saveCheckQr } from '@/js/api';

export default {
  data() {
    return {
      qrCode: null,
      codeReader: null,
      scan: true,
    };
  },
  computed: {
    processId() {
      return this.$route.params.processId;
    }
  },
  mounted() {
    this.startScanner();
  },
  beforeDestroy() {
    if (this.codeReader) {
      this.stopScanner();
    }
  },
  methods: {
    startScanner() {
      this.codeReader = new BrowserQRCodeReader();
      this.codeReader
          .decodeFromVideoDevice(null, this.$refs.video, async (result, error) => {
            if (result) {
              this.qrCode = result.getText();
              if (this.qrCode != null){
                try {
                  await saveCheckQr(this.processId, this.qrCode);
                  this.scan = false;
                  console.log('Результат сканирования успешно сохранен');
                } catch (err) {
                  console.error('Ошибка при сохранении результата:', err);
                }
              }
            }
          })
          .then(() => {
            console.log('Сканер QR-кодов работает');
          })
          .catch((err) => {
            console.error('Ошибка инициализации сканера:', err);
          });
    },
    stopScanner() {
      if (this.codeReader) {
        this.codeReader.reset();
      }
    },
  },
};
</script>

<style scoped>
.container {
  padding: 30px;
  margin-top: 50px;
}

.scanner-box {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

.video-container {
  border: 3px solid #007bff;
  border-radius: 8px;
  overflow: hidden;
  background-color: #000;
}

video {
  border-radius: 8px;
  display: block;
  margin: 0 auto;
}

.qr-code-result {
  font-size: 18px;
  color: #333;
  font-weight: 500;
  margin-top: 15px;
  text-align: center;
}

.qr-code-result span {
  color: #007bff;
  font-weight: bold;
}
</style>
