import axios from 'axios';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL || 'http://localhost:3000';

export async function logIn(login, password) {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/bukovel/auth/login`, { login, password });
        return response.data;
    } catch (error) {
        if (error.response) {
            if (error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error(`Помилка: ${error.response.status} ${error.response.statusText}`);
            }
        } else if (error.request) {
            console.error('Проблема с сетевым подключением или сервер не ответил:', error.request);
            throw new Error('Проблема с сетевым подключением. Пожалуйста, проверьте интернет.');
        } else {
            console.error('Ошибка при отправке запроса:', error.message);
            throw new Error('Помилка при отправке запиту.');
        }
    }
}


export async function getAllPayments() {
    try {
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Токен не найден. Пожалуйста, выполните вход.');
        }

        console.log('Используемый токен:', `Bearer ${token}`);

        const response = await axios.get(`${API_BASE_URL}/api/bukovel/payments/get-all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        if (error.response) {
            console.error(`Ошибка: ${error.response.status} - ${error.response.data.message}`);
        } else {
            console.error('Ошибка при загрузке платежей:', error);
        }
        throw error;
    }
}

export async function refundPayment(invoice_id) {
    try {
        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        const response = await axios.post(`${API_BASE_URL}/api/bukovel/payments/refund/${invoice_id}`, {}, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Помилка при поверненні коштів.');
    }
}

export async function successPayment(invoice_id) {
    try {
        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        const response = await axios.post(`${API_BASE_URL}/api/bukovel/payments/success/${invoice_id}`, {}, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Помилка при пітвердженні платежу.');
    }
}

export async function saveCheckQr(processId, resultScan) {
    try {
        const headers = {
            'Authorization': `Bearer ddb890d306029ed4ad746e86ec4f26342a5a15c9a7bbd3942ebacf651aa0b63f`,
        };

        const data = {
            processId: processId,
            processResult: resultScan
        };

        const response = await axios.post(`${API_BASE_URL}/api/bukovel/check/`, data, { headers });
        return response.data;
    } catch (err) {
        console.error('Ошибка отправки запроса', err);
        throw new Error('Не удалось сохранить данные сканирования');
    }
}


